<template>
  <div class="main-conent main-conent-screen main-conent-bgFFF main-conent-borderradius">
    <div class="screen-header">
        <el-row>
            <el-col :span="8">
                <el-button type="primary" @click="handleCompound(ids)">批量合成</el-button>
            </el-col>
            <el-col :span="16" class="text-right">
                <el-input
                    class="margin-l10"
                    style="width:200px;"
                    placeholder="请输入搜索内容"
                    clearable
                    v-model="queryParams.search">
                    <i slot="suffix" class="el-input__icon el-icon-search cursor-pointer" @click="getList()"></i>
                </el-input>
            </el-col>
        </el-row>              
    </div>

    <screen-table
        class="screen-conent"
        table-class="custom-table"
        header-row-class-name="custom-table-header"
        @selection-change="handleSelectionChange"
        :data="tableData">
            <el-table-column
                fixed
                type="selection"
                width="40">
            </el-table-column>
            <el-table-column
                prop="id"
                label="主键"
                width="60">
            </el-table-column>
            <el-table-column
                prop="scriptId"
                label="脚本ID"
                width="70">
            </el-table-column>
            <el-table-column
                prop="status"
                label="状态"
                width="60">
                <template slot-scope='scope'>
                    <span>{{ selectDictLabel(statusSelect, scope.row.status) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="groupInfoList"
                label="组合">
                <template slot-scope='scope'>
                  <div>
                    <div v-for="(item, index) in scope.row.groupInfoList" :key="index" style="float: left; margin-left: 10px;">
                      <el-tooltip class="item" effect="dark" :content="item" placement="top">
                        <div v-if="item.indexOf('http') >= 0">
                          <img :src="item" width="100px" v-if="item.indexOf('.png') >= 0">
                          <audio controls width="100px" v-else-if="item.indexOf('.wav') >= 0">
                              <source :src="item" type="audio/wav">
                              您的浏览器不支持播放该音频格式。
                          </audio>
                          <video width="100px" v-else :src="item"></video>
                        </div>
                        <div v-else>
                          <el-button @click="() => handleDownload(item)" type="text">下载</el-button>
                        </div>
                      </el-tooltip>
                    </div>
                  </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="path"
                label="视频地址"
                width="100">
                <template slot-scope='scope'>
                  <div v-if="scope.row['path']">
                    <el-tooltip class="item" effect="dark" :content="scope.row['path']" placement="top">
                      <div v-if="scope.row['path'].indexOf('http') >= 0">
                        <img :src="scope.row['path']" width="100px" v-if="scope.row['path'].indexOf('.png') >= 0">
                        <audio controls width="100px" v-else-if="scope.row['path'].indexOf('.wav') >= 0">
                            <source :src="scope.row['path']" type="audio/wav">
                            您的浏览器不支持播放该音频格式。
                        </audio>
                        <video width="100px" v-else :src="scope.row['path']"></video>
                      </div>
                      <div v-else>
                        <el-button @click="() => handleDownload(scope.row['path'])" type="text">下载</el-button>
                      </div>
                    </el-tooltip>
                  </div>
                  <div v-else>
                    <span></span>
                  </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="createTime"
                width="140"
                label="创建时间">
                <template slot-scope='scope'>
                    <span>{{ parseTime(scope.row.createTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="120">
                <template slot-scope="scope">
                    <!-- <el-button @click="tableEdit(scope.row)" type="text" :disabled="scope.row.status === 1">编辑</el-button> -->
                    <el-button @click="handleCompound([scope.row.id])" type="text" :disabled="scope.row.status === 1">合成</el-button>
                    <el-button @click="tableDel(scope.row)" type="text" :disabled="scope.row.status === 1">删除</el-button>
                </template>
            </el-table-column>
    </screen-table>

    <div class="screen-footer text-right">
        <el-pagination
            background
            layout="sizes, prev, pager, next, total"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            @current-change="handlePageNo"
            @size-change="handlePageSize"
        >
        </el-pagination>
    </div>
    
    <!-- 添加或修改对话框 -->
    <el-dialog :title='title' :visible.sync='open' width='500px' :before-close='cancel'>
        <el-form ref='form' :model='form' :rules='rules' label-width='130px'>
            <el-form-item label='类型' prop='type'>
                <el-select v-model="form.type" placeholder="请选择类型">
                  <el-option
                    v-for="item in statusSelect"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label='名称' prop='name'>
                <el-input v-model='form.name' placeholder='请输入名称' />
            </el-form-item>
            <el-form-item label='模版Key' prop='model'>
                <el-input v-model='form.model' placeholder='请输入模版Key' />
            </el-form-item>
            <el-form-item label='采样率' prop='sampleRate'>
                <el-input-number v-model='form.sampleRate' placeholder='请输入采样率' />
            </el-form-item>
            <el-form-item label='音量' prop='volume'>
                <el-input-number v-model='form.volume' placeholder='请输入音量' :min="-500" :max="500"/>
            </el-form-item>
            <el-form-item label='语速' prop='rate'>
                <el-input-number v-model='form.rate' placeholder='请输入语速' :min="-500" :max="500"/>
            </el-form-item>
            <el-form-item label='语调' prop='pitch'>
                <el-input-number v-model='form.pitch' placeholder='请输入语调' :min="-500" :max="500"/>
            </el-form-item>
            <el-form-item label='描述' prop='remark'>
                <el-input v-model='form.remark' placeholder='请输入描述' type="textarea" :rows="2"/>
            </el-form-item>
        </el-form>
        <div slot='footer' class='dialog-footer'>
        <el-button type='primary' @click='submitForm'>确 定</el-button>
        <el-button @click='cancel'>取 消</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>

import ScreenTable from '@/components/ScreenTable';
import { mashupTaskPage, updateMashupTask, deleteMashupTask, mashupTaskCompound } from '@/service/mashup';
import { download } from '@/service/fileInfo';

export default {
  components: {
      ScreenTable
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        search: undefined
      },
      total: 0,
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        code: [
          { required: true, message: '角色代码不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '角色名称不能为空', trigger: 'blur' }
        ]
      },
      statusSelect: [
        {key: 0, value: '初始化'},
        {key: 1, value: '生成中'},
        {key: 2, value: '已完成'},
        {key: 3, value: '异常'},
        {key: 4, value: '删除'}
      ],
      ids: []
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      mashupTaskPage(this.queryParams).then(response => {
        this.tableData = response['data']['records'];
        this.total = response['data']['total'];
      });
    },
    handlePageNo(pageNo) {
      this.queryParams = {
        ...this.queryParams,
        pageNo
      };
      this.getList();
    },
    handlePageSize(pageSize) {
      this.queryParams = {
        ...this.queryParams,
        pageSize
      };
      this.getList();
    },
    handleAdd() {
        this.title = '新增';
        this.open = true;
        this.form = {};
    },
    tableEdit(row){
        this.title = '修改';
        this.open = true;
        this.form = {...row};
    },
    handleSelectionChange(val) {
      this.ids = val.map((item) => item['id']);
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.resetForm('form');
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          updateMashupTask(this.form).then(response => {
            if (response.code === 0) {
              this.cancel();
              this.getList();
            } else {
              this.$message({
                  type: 'info',
                  message: '异常'
              });  
            }
          });
        }
      });
    },
    tableDel(row){
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
        deleteMashupTask({id: row.id}).then(response => {
            if (response.code === 0) {
              this.$message({
                  type: 'success',
                  message: '删除成功!'
              });
              this.getList();
            } else {
              this.$message({
                  type: 'info',
                  message: '异常'
              });  
            }
          });
      }).catch(() => {
          this.$message({
              type: 'info',
              message: '已取消删除'
          });          
      });
    },
    handleDownload(path){
      download({path}).then(response => {
        let split = response.headers['content-disposition'].split('=');
        let name = split[split.length - 1];
        let url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.style.display = "none";
        a.click();
        document.body.removeChild(a);
      });
    },
    handleCompound(ids = []){
      if(ids.length === 0){
        this.$message({
            type: 'info',
            message: '请选择'
        });  
        return;
      }
      mashupTaskCompound({ids}).then(response => {
        if (response.code === 0) {
          this.$message({
              type: 'success',
              message: '操作成功!'
          });
          this.getList();
        } else {
          this.$message({
              type: 'info',
              message: '异常'
          });  
        }
      });
    }
  }
};
</script>